import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg">
          <div className="onas">
            <div className="wrapper onas">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="section"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="link">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span>
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                    <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a>
                    <span />
                  </nav>
                </div>
              </header>
              <div className="top">
                <h2>
                  O marce
                  <br /> NEUTROGENA<sup>®</sup>
                </h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/">
                    O Neutrogenie
                  </a>{" "}
                  <span>&gt;</span>
                  Historia
                </div>
              </div>
              <div></div>
              <div className="submenu">
                <div className="wrapper">
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    Kim jesteśmy
                  </a>
                  <a href="/site/o-neutrogenie/historia/" className="current">
                    Historia
                  </a>
                  <a
                    href="/site/o-neutrogenie/formula-norweska/"
                    className="link"
                  >
                    Formuła Norweska
                  </a>
                </div>
              </div>
              <div className="wrapper">
                <div className="content">
                  <section>
                    <div className="text half left">
                      <span className="border" />
                      <p></p>
                      <p>
                        <img src="/site/nowa/img/hist1.jpg" />
                        <br />
                        <span className="h2">1930</span>
                        <br />
                        <br />
                        <span>
                          Historia marki NEUTROGENA<sup>®</sup>&nbsp;sięga 1930
                          roku, w którym to Emanuel “Manny“ Stolaroff założył
                          niewielką firmę kosmetyczną Natone z siedzibą w Los
                          Angeles w Kalifornii. W pierwszych latach działalności
                          spółka Natone była dostawcą produktów do salonów
                          piękności, których klientami byli zazwyczaj ludzie
                          związani z&nbsp;przemysłem filmowym. Nic więc
                          dziwnego, że wśród oferowanych produktów znalazły się
                          takie specyfiki jak rzęsy z prawdziwymi diamentami lub
                          futrem, czy utwardzacze paznokci. Jednak już od lat
                          40. firma zaczęła produkować i dystrybuować kosmetyki
                          dla rynku detalicznego.&nbsp;
                        </span>
                        <br />
                        <br />
                        <span>
                          W roku 1950 "Manny" wyruszył w podróż po świecie w
                          poszukiwaniu nowych inspiracji. Dla zmylenia
                          konkurencji przyjął pseudonim Mr Idea, pod którym
                          realizował swoje nowe pomysły.
                        </span>
                      </p>
                      <p />
                    </div>
                    <div className="text half right">
                      <p>
                        <br />
                      </p>
                      <p>
                        <span className="h2">1954</span>
                        <br />
                        <br />
                        <span>
                          <img
                            className="left"
                            title
                            src="/site/assets/Uploads/hist2.jpg"
                            alt="hist2"
                            width="264"
                            height="316"
                          />
                          Przełomem w podróży okazał się rok 1954. To właśnie
                          wtedy "Manny" usłyszał o niezwykłych właściwościach
                          mydła, które wynalazł wnuk osobistego lekarza
                          Napoleona - dr. Edmond Fromont. To łagodne i
                          niepodrażniające skóry mydło nie pozostawiało osadu na
                          skórze.
                        </span>
                        <br />
                        <span>
                          Dzięki temu odczyn pH skóry wracał do swego
                          naturalnego stanu prawie tak szybko, jak po myciu
                          czystą wodą. Zafascynowany jego właściwościami,
                          "Manny" Stolaroff postanowił importować je do Stanów
                          pod nazwą NEUTROGENA<sup>®</sup>.
                        </span>
                      </p>
                    </div>
                  </section>
                  <section>
                    <div className="bg hist1">
                      <div className="text right">
                        <h2>Lata 60. XX wieku</h2>
                        <p>
                          Wysokogatunkowe, mydło NEUTROGENA<sup>®</sup>{" "}
                          natychmiast zostało zauważone i docenione, znajdując
                          szeroki rynek zbytu w domach towarowych i sklepach z
                          dobrej jakości kosmetykami.
                          <br />
                          Niebawem bursztynowa kostka stała się okrętem flagowym
                          rosnącej w siłę firmy. Na początku lat 60. XX wieku
                          spółka zmieniła nazwę na Neutrogena&nbsp;Corporation.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div className="bg hist2">
                      <div className="text left">
                        <h2>Lloyd Cotsen</h2>
                        <p>
                          Sukces przypieczętował nowy prezes Lloyd Cotsen, który
                          postawił na konsekwentne promowanie walorów
                          dermatologicznych mydła. Niezmiennie najwyższa jakość
                          kosmetyków, o której zaświadczyć mogą kolejne
                          pokolenia, ugruntowała pozycję marki NEUTROGENA
                          <sup>®</sup> jako jednego ze światowych liderów na
                          rynku kosmetycznym.
                          <br />
                          <br />
                          Kolejnym przełomowym wydarzeniem w dziejach spółki
                          było powstanie Formuły Norweskiej, unikatowej
                          receptury będącej odpowiedzią na potrzeby skóry
                          narażonej na surowe warunki klimatyczne.
                        </p>
                      </div>
                    </div>
                  </section>
                  <section className="last">
                    <div className="bg hist3">
                      <div className="text right">
                        <h2>Lata 90. XX wieku</h2>
                        <p>
                          Neutrogena Corporation&nbsp;od blisko 20 lat jest
                          częścią firmy&nbsp;Johnson &amp; Johnson Family of
                          Companies. Marka cieszy się wiarygodnością i
                          niesłabnącą popularnością na całym świecie. Opinię tę
                          zawdzięcza przede wszystkim swym priorytetowym
                          wartościom - zaangażowaniu na rzecz konsumentów oraz
                          ponadprzeciętnej trosce o jakość i walory swych
                          produktów, rekomendowanych przez lekarzy.
                          <br />W epoce, w której konsumenci bardziej niż
                          kiedykolwiek w przeszłości utożsamiają piękno ze
                          zdrowiem, NEUTROGENA<sup>®</sup> jest najlepszym
                          rozwiązaniem, czego dowodem jest stale rosnąca liczba
                          usatysfakcjonowanych klientów.
                        </p>
                      </div>
                    </div>
                  </section>
                  <div id="social_id_12"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/site/o-neutrogenie/kim-jestesmy/">
                  neutrogena<sup>®</sup> obecnie
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/formula-norweska/">
                  czym jest formuła norweska?
                </a>
                <span>|</span>
                <a href="/site/o-neutrogenie/historia/">
                  poznaj naszą historię
                </a>
                <span>|</span>
                <a href="/site/produkty/">zobacz produkty neutrogena</a>
              </div>
            </div>
          </div>
          <footer>
            <div className="wrapper">
              <nav>
                <a href="/site/" className="logo2" />
                <a href="/site/nota-prawna/">Nota Prawna</a>
                <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
                <a href="/site/cookies/">Polityka cookies</a>
                 <a href="/site/kontakt/">Zachęcamy do kontaktu</a>                   
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
              <p>
                Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
                Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
                113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
                JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną
                odpowiedzialność za treści zawarte na niniejszej stronie.
                <br /> Strona jest przeznaczona dla użytkowników z Polski.
                Ostatnia aktualizacja: 01/02/2023
                <br />{" "}
              </p>{" "}
              <p>
                <span>
                  Strona została zoptymalizowana pod kątem przeglądarek:
                  Internet Explorer 7 i wyższych, Mozilla Firefox, Google
                  Chrome, Opera, Safari
                </span>{" "}
                <span className="right">
                  Zalecana rozdzielczość: 1024 x 768 pikseli
                </span>{" "}
              </p>
            </div>
          </footer>
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-N7SHM8');"
            }}
          />
          <script type="text/plain" class="optanon-category-4" 
            dangerouslySetInnerHTML={{
              __html:
                "\n!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;\nn.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,\ndocument,'script','https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '227575234312850'); // Insert your pixel ID here.\nfbq('track', 'PageView');\n"
            }}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
